import React, {useState, useEffect} from 'react';
import {useSpring, animated} from 'react-spring';
import {withGesture} from 'react-with-gesture';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

function ChoiceAnswer(props) {
  // Factor with which the x movement is amplified.
  const xAccelerator = 1.5;
  // The value of the chosen option.
  const [answerValue, setAnswerValue] = useState('');
  const [answerIndex, setAnswerIndex] = useState(null);
  // If a choice has been made.
  const isAnswered = (answerValue !== '');
  // The translation hook.
  const {t} = useTranslation();
  const renderButtons = (answers) => {
    return answers.map((answer) => {
      return (
          <div className="choice__field form-field step-choice" key={answer.value}>
            <input
                type="radio"
                value={answer.value}
                id={answer.id}
                name={props.id}
                checked={answerValue === answer.value}
                onChange={() => {
                  setAnswerValue(answer.value);
                }}/>
            <label htmlFor={answer.id}>{answer.text}</label>
          </div>
      );
    });
  };

  const {delta: [xDelta], down} = props;

  const {visibility, x} = useSpring({
        to: {
          visibility: isAnswered ? 'hidden' : 'visible',
          x: down ? xDelta : 0,
        },
      },
  );
  const trans = (x) => {
    const xAcceled = xAccelerator * x;
    const width = 128;
    const threshold = .75*width;

    if (!isAnswered && xAcceled <= -1*threshold) {
      setAnswerValue(props.answers[0].value);
      setAnswerIndex(0);
      return `translate3d(-${width}}px,0,0)`;
    } else if (!isAnswered && xAcceled >= threshold) {
      setAnswerValue(props.answers[1].value);
      setAnswerIndex(1);
      return `translate3d(${width}px,0,0)`;
    } else {
      return `translate3d(${xAcceled}px,0,0)`;
    }
  };

  // If there is an answer, call the valueChanged callback.
  useEffect(() => {
    if (isAnswered) {
      props.valueChanged(answerValue, answerIndex);
    }
  });

  return (
      <div className="choice">
        <h3 className="choice__instruction">
          {t('input.choice.instruction')}
        </h3>
        <div className="choice__buttons">
          <animated.div className="choice__thumb" style={{visibility, transform: x.interpolate(trans)}}>
            <i className="icon-chevron-left"/>
            <span className="choice__dragger-interaction">
              {t('input.choice.interaction')}
            </span>
            <i className="icon-chevron-right"/>
          </animated.div>
          {renderButtons(props.answers)}
        </div>
      </div>
  );
}

ChoiceAnswer.propTypes = {
  answers: PropTypes.arrayOf(
      PropTypes.shape({
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          },
      )).isRequired,
  delta: PropTypes.any,
  down: PropTypes.any,
  id: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  valueChanged: PropTypes.func
};

export default withGesture({})(ChoiceAnswer);