import {getQuestionnaire} from '../utils/api';
import {previewConfiguration} from '../utils/preview';

export const LOADED_CONFIGURATION = 'app:loadedConfiguration';
export const PREVIEW_CONFIGURATION = 'app:loadPreviewConfiguration';

export function getConfiguration(successCallback, failureCallback) {
  return (dispatch) => {

    getQuestionnaire(
      (result) => {
        dispatch({
          type:  LOADED_CONFIGURATION,
          payload: result,
        });

        successCallback();
      },
      (message) => {
        failureCallback(message);
      }
    );
  }
}

export function loadPreviewConfiguration(payload) {
  return (dispatch) => {
    dispatch({
      payload: Object.assign({}, previewConfiguration, payload),
      type: PREVIEW_CONFIGURATION,
    });
  };
}