import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class AnswerHover extends Metric
{
  constructor() {
    super(document.getElementsByClassName('step-option'), ['mouseenter', 'mouseleave']);
    this.hoverTreshold = 200; // Least amount of ms to hover over answer to track it
    this.mouseEnterTime = null;
    this.mouseEnterTarget = null;
  }

  handleEvent(event) {
    if (event.target.nodeName !== "LABEL") {
      return;
    }
    if (event.type === 'mouseenter') {
      this.handleMouseEnter(event);
    } else if (event.type === 'mouseleave') {
      this.handleMouseLeave(event);
    }
  }

  handleMouseEnter(event) {
    this.debug('AnswerHover.enter', event.target.htmlFor);
    this.mouseEnterTime = Date.now();
    this.mouseEnterTarget = event.target.htmlFor;
  }

  handleMouseLeave(event) {
    this.debug('AnswerHover.leave', event.target.htmlFor);
    const now = Date.now();

    if (now - this.mouseEnterTime >= this.hoverTreshold) {
      const answer = this.cleanupAnswer(this.mouseEnterTarget);
      this.events.push(new TrackingEvent('answerHover', answer, this.mouseEnterTime));
      this.events.push(new TrackingEvent('answerBlur', answer, now));
    }

    this.mouseEnterTarget = null;
    this.mouseEnterTime = null;
  }

  cleanupAnswer(answer) {
    const nameMatch = answer.match(/[A-Z]+\d+-[A-Z]/g);
    if (nameMatch && nameMatch.length === 1) {
      return nameMatch[0];
    }
  }
}
