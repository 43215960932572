/**
 * Functions related to receiving the preview state
 */

export const isPreview = () => {
  let preview = new URLSearchParams(window.location.search).get('preview');
  return preview !== null;
};

/**
 * Initial state / config related to receiving the preview state
 */
export const previewConfiguration = {
  'content': {
    'intro': [
      {
        'title': 'We\u2019ve got a few questions for you to work out if you can get a loan.',
        'text': 'It\u2019ll take around 5 minutes.',
      },
    ],
    'complete': [
      {
        'title': 'Congratulations, you have completed the questionnaire!',
        'text': 'send an email to info@advicerobo.com to learn more about our services.',
      }],
  },
  'colors': {
    'pageBg': '#F85972',
    'pageText': '#FFF',
    'pageBtnBg': '#0000CC',
    'pageBtnText': '#FFF',
    'loadBg': '#010127',
    'loadText': '#FFF',
    'questionHeadBg': '#0000CC',
    'questionHeadText': '#FFF',
    'questionBg': '#F9F9F9',
    'questionAnswerBg': '#FFF',
    'questionAnswerActive': '#0000CC',
    'questionAnswerText': '#000',
    'questionAnswerActiveText': '#FFF',
    'questionAnswerHelper': '#F85972',
    'questionBtnBg': '#F85972',
    'questionBtnText': '#FFF',
    'promptOverlay': 'rgba(0, 0, 0, 0.27)',
    'promptBg': '#FFF',
    'promptText': '#000',
    'promptBtnBg': '#C0C0C0',
    'promptBtnText': '#000',
    'progress': '#0000CC',
  },
  'questionnaireMeta': {'lang': 'en', 'version': '7.2.2', 'totalQuestions': 21},
  'translations': {
    'continue': 'Continue',
  },
  'options': {'skipIntro': false},
  'extraCssUrl': null,
  'backUrl': '',
  'pingUrl': null,
};

export const previewQuestion = {
  "id": "TEST",
  "title": "",
  "text": "This is an example question. This questionnaire has limited functionality.",
  "image": null,
  "category": "TEST",
  "type": "level",
  "answers": [
    {
      "id": "TEST-A",
      "text": "Strongly disagree",
      "image": null,
      "value": "4"
    },
    {
      "id": "TEST-B",
      "text": "Disagree",
      "image": null,
      "value": "3"
    },
    {
      "id": "TEST-C",
      "text": "Neither agree nor disagree",
      "image": null,
      "value": "2"
    },
    {
      "id": "TEST-D",
      "text": "Agree",
      "image": null,
      "value": "1"
    },
    {
      "id": "TEST-E",
      "text": "Strongly agree",
      "image": null,
      "value": "0"
    }
  ],
  "steps": [
    1
  ],
  "sequenceNumber": 1,
  "validations": {}
};