import React from 'react';
import PropTypes from "prop-types";

export default class RangeSliderAnswer extends React.Component {

  constructor(props) {
    super(props);

    this.totalSize = 256; // Height in px of the slider
    this.absoluteStepSize = this.totalSize / (this.props.range / this.props.stepSize); // Height in px of each step

    this.state = {
      changing: false,
      valueLabel: this.props.defaultValue,
      value: this.props.startPosition,
      initialY: (1 - this.props.startPosition) * this.totalSize,
      currentY: (1 - this.props.startPosition) * this.totalSize,
    };

    this.endChangingHandler = this.onEndChanging.bind(this);
    this.changeHandler = this.onChange.bind(this);
  }

  static propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    range: PropTypes.number.isRequired,
    rangeDecimals: PropTypes.number.isRequired,
    rangeUnit: PropTypes.string.isRequired,
    stepSize: PropTypes.number.isRequired,
  };

  onStartChanging(event) {
    const initialY = (event.type === "touchstart" ? event.touches[0].clientY : event.clientY) - this.state.currentY;
    this.setState({changing: true, initialY: initialY});

    // Create listeners for mouse/touch events
    window.addEventListener('mouseup', this.endChangingHandler);
    window.addEventListener('mousemove', this.changeHandler);
    window.addEventListener('touchend', this.endChangingHandler);
    window.addEventListener('touchmove', this.changeHandler);
  }

  onEndChanging() {

    this.setState({changing: false, initialY: this.state.currentY});

    // Remove listeners for mouse/touch events
    window.removeEventListener('mouseup', this.endChangingHandler);
    window.removeEventListener('mousemove', this.changeHandler);
    window.removeEventListener('touchend', this.endChangingHandler);
    window.removeEventListener('touchmove', this.changeHandler);
  }

  onChange(event) {

    if (this.state.changing) {
      let y = (event.type === "touchmove" ? event.touches[0].clientY : event.clientY) - this.state.initialY;

      // Get max from parent and make it negative
      let minSize = this.totalSize - this.totalSize * this.props.min; // min = bottom = props.totalSize
      let maxSize = this.totalSize - this.totalSize * this.props.max; // max = top = 0

      // Limit the position between minSize and maxSize
      y = y < maxSize ? maxSize : y;
      y = y > minSize ? minSize : y;

      // Round Y to snap to nearest step
      y = this.absoluteStepSize * Math.round(y / this.absoluteStepSize);

      // Dispatch position to parent
      const value = 1 - (y / this.totalSize);
      this.props.onChange(value);

      // Calculate value label based on position
      const valueLabel = (this.props.range * value + this.props.minValue).toFixed(this.props.rangeDecimals);
      this.setState({currentY: y, valueLabel: valueLabel});
    }
  }

  render() {

    return(
      <div className={`range-slider ${this.props.className}`} >
        <div
          onMouseDown={this.onStartChanging.bind(this)}
          onTouchStart={this.onStartChanging.bind(this)}
          className="range-slider__thumb"
          style={{ transform: `translateY(${this.state.currentY}px)` }}
        >{this.props.rangeUnit} {this.state.valueLabel}</div>
      </div>
    );
  }
}