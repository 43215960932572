import {QUESTION_LOADED, FETCH_FAILED, CLEAR_ERROR, PREVIEW_QUESTION} from '../actions/questionnaire';

export const initialQuestionnaireState = {
  currentQuestion: null,
  error: null,
};

export default function questionnaireReducer(state = initialQuestionnaireState, action) {
  switch (action.type) {
    case QUESTION_LOADED:
    case PREVIEW_QUESTION:
      // Set currentQuestion value of the state, without mutating (but creating a new object instead)
      return {
        ...state, // keep the rest of the data
        currentQuestion: action.payload, // overwrite current question
      };

    case FETCH_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
}