import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class MouseScroll extends Metric
{
  constructor() {
    super(window, 'scroll', false);
    this.previousTimestamp = 0;
    this.scrollTimeout = 500;
    this.scrollCount = 0;
    this.previousScrollTop = 0;
    this.previousDirection = 'down';
  }

  reset() {
    this.previousTimestamp = 0;
    this.scrollCount = 0;
  }

  handleEvent(event) {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollDirection = (scrollTop > this.previousScrollTop) ? 'down' : 'up';

    // If we pass the timeout threshold, we count this as a new scroll
    // If the user changes direction of scrolling, this also counts as a new scroll
    if (
        (event.timeStamp - this.previousTimestamp > this.scrollTimeout) ||
        scrollDirection !== this.previousDirection
    ) {
      this.scrollCount++;
      this.debug('MouseScroll', this.scrollCount);
    }
    this.previousTimestamp = event.timeStamp;
    this.previousScrollTop = scrollTop;
    this.previousDirection = scrollDirection;
  }

  getEvents() {
    if (this.scrollCount === 0) {
      return [];
    }
    return [new TrackingEvent('scroll', null, Date.now(), this.scrollCount)];
  }
}