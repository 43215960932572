import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Translation} from 'react-i18next';
import {loadNextQuestion} from '../actions/questionnaire';
import {notifyParentOfNewHeight} from '../utils/iframe';
import Processing from '../components/processing';
import Question from '../components/question';
import Watermark from '../components/watermark';
import ErrorPrompt from '../components/errorPrompt';
import BehaviourTracking from '../utils/behaviourTracking';

class QuestionnairePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      lastAnswer: {
        answer: '',
        id: 'start',
        step: 0,
      },
    };
    this.errorButtonHandler = this.errorButtonHandler.bind(this);
  }

  static propTypes = {
    questionnaire: PropTypes.shape({
      currentQuestion: PropTypes.shape({
        answers: PropTypes.array.isRequired,
        id: PropTypes.string.isRequired,
        image: PropTypes.string,
        sequenceNumber: PropTypes.number.isRequired,
        steps: PropTypes.arrayOf(PropTypes.number).isRequired,
        text: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
      error: PropTypes.string,
    }),
    totalQuestions: PropTypes.number.isRequired,
    loadNextQuestion: PropTypes.func.isRequired,
    history: PropTypes.object,
    location: PropTypes.object,
    backUrl: PropTypes.string,
    pingUrl: PropTypes.string,
    tracker: PropTypes.instanceOf(BehaviourTracking).isRequired,
  };

  componentDidMount() {
    this.loadNextQuestion();
    notifyParentOfNewHeight(false);
  }

  onLoadingFinished(response) {
    if (response.finished) {
      if (this.props.backUrl) {
        window.top.location = this.props.backUrl;
      } else {
        this.props.history.push('/complete');
      }
    } else if (response.redirect) {
      window.top.location = response.redirect;
    } else {
      this.setState({loading: false});
    }
    this.props.tracker.restart();
  }

  loadNextQuestion() {
    this.setState({loading: true});
    const answer = this.state.lastAnswer;
    this.props.loadNextQuestion(this.props.pingUrl, answer.answer, answer.index, answer.id, answer.step,
        this.onLoadingFinished.bind(this));
    // Reset the height
    notifyParentOfNewHeight(true);
  }

  errorButtonHandler(error) {
    if (error === 'errors.questionnaire_completed') {
      return this.onLoadingFinished({finished: true});
    }

    this.loadNextQuestion();
  }

  onSubmitAnswer(answer, index) {
    const currentQuestion = this.props.questionnaire.currentQuestion;
    this.setState({
          lastAnswer: {
            answer: answer,
            id: currentQuestion.id,
            index: index,
            step: currentQuestion.steps[0],
          },
        },
        () => {
          this.props.tracker.report(currentQuestion.id);
          this.loadNextQuestion();
        });
  }

  renderProgress() {
    let percent = 0;
    if (this.props.questionnaire.currentQuestion) {
      percent = this.props.questionnaire.currentQuestion.sequenceNumber / this.props.totalQuestions * 100;
    }
    return (
        <div className="template-step__progress" style={{width: `${percent}%`}}/>
    );
  }

  renderQuestion(question) {
    if (question === null || this.state.loading) {
      return '';
    }

    return (
        <Question
            {...question}
            submitAnswer={this.onSubmitAnswer.bind(this)}
        />
    );
  }

  render() {
    return (
        <Translation>
          {(t) =>
              <div className="page template-step">
                <div className="page__background">
                  <Watermark/>
                </div>
                <div className="page__container">
                  <section className="page__header"/>
                  <section className="page__content">

                    {this.props.questionnaire.error ?
                        <ErrorPrompt
                            buttonHandler={this.errorButtonHandler}
                            message={this.props.questionnaire.error}
                            buttonLabel={'interface.retry'}
                        /> :
                        <div className="section">
                          {this.renderQuestion(this.props.questionnaire.currentQuestion)}
                        </div>
                    }
                  </section>
                  <section className="page__footer">
                    {this.renderProgress()}
                  </section>
                </div>
                {
                  !this.state.loading ||
                  <Processing text={t('interface.loading_question')}
                              longWaitText={t('interface.loading_question_long')}/>
                }
              </div>
          }
        </Translation>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    questionnaire: state.questionnaire,
    totalQuestions: state.app.questionnaireMeta.totalQuestions,
    pingUrl: state.app.pingUrl,
    backUrl: state.app.backUrl,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators({loadNextQuestion: loadNextQuestion}, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(QuestionnairePage);