import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class MouseMovement extends Metric
{
  constructor() {
    super(window, 'mousemove');
    this.previousX = null;
    this.previousY = null;
    this.movementTimeout = 500;
    this.reset();
  }

  reset() {
    this.totalDistance = 0;
    this.movementCount = 0;
    this.previousTimestamp = 0;
    this.totalMovementTime = 0;
    this.movementStartTime = 0;
  }

  handleEvent(event) {
    this.handleMouseMove(event);
    this.handleMouseDistance(event);

    if (this.debugging && this.totalDistance > 10000) {
      this.debug('MouseMovement.distance', this.totalDistance);
    }
  }

  handleMouseMove(event) {
    const timeElapsed = event.timeStamp - this.previousTimestamp;
    if (timeElapsed > this.movementTimeout || this.previousTimestamp === 0) {
      this.movementCount++;
      this.debug('MouseMovement.number', this.movementCount);

      this.flushMoveMovementTime();
    }

    if (this.movementStartTime === 0) {
      this.movementStartTime = event.timeStamp;
    }
    this.previousTimestamp = event.timeStamp;
  }

  flushMoveMovementTime() {
    const previousMovementTime = (this.previousTimestamp - this.movementStartTime);
    this.totalMovementTime += previousMovementTime;
    this.debug('MouseMovement.time (previous move)', previousMovementTime);
    this.movementStartTime = 0;
  }

  handleMouseDistance(event) {
    if (this.previousX !== null && this.previousY !== null) {
      // Calculate distance between mouse position and previous
      // Finally a use for all that Pythagoras I learned in high school
      const distance = Math.sqrt(
          Math.pow(event.clientX - this.previousX, 2) +
          Math.pow(event.clientY - this.previousY, 2)
      );
      this.totalDistance += Math.abs(distance);
    }

    this.previousX = event.clientX;
    this.previousY = event.clientY;
  }

  getEvents() {
    if (this.totalDistance === 0 && this.movementCount === 0 && this.totalMovementTime === 0) {
      return [];
    }
    this.flushMoveMovementTime();
    return [
        new TrackingEvent('distance', null, Date.now(), Math.round(this.totalDistance)),
        new TrackingEvent('move', null, Date.now(), this.movementCount),
        new TrackingEvent('time', null, Date.now(), Math.round(this.totalMovementTime)),
    ];
  }
}