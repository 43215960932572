import React, { Component } from "react";

import { HexagonSVG } from '../settings/svg';

export default class Spinner extends Component {

  render() {
    return(
      <div className="spinner">
        <HexagonSVG className="spinner-1" id="spinner-1"/>
        <HexagonSVG className="spinner-2" id="spinner-2"/>
      </div>
    );
  }
}