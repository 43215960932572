import {applyMiddleware, compose, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';

// Reducers
import appReducer, {initialAppState} from '../reducers/app';
import questionnaireReducer, {initialQuestionnaireState} from '../reducers/questionnaire';

const reducers = combineReducers({
  app: appReducer,
  questionnaire: questionnaireReducer,
});

const initialStates = {
  app: initialAppState,
  questionnaire: initialQuestionnaireState,
};

// Middleware
const USE_DEVTOOLS = process.env.REACT_APP_DEBUG === 'true'
    && window.navigator.userAgent.includes // Check for IE11 and older.
    && window.navigator.userAgent.includes('Chrome')
    && window.__REDUX_DEVTOOLS_EXTENSION__;

let storeEnhancers = null;
if (USE_DEVTOOLS) {
  storeEnhancers = compose(
      applyMiddleware(thunk), // use for synchronous logic that needs access to the store, simple async logic like AJAX requests.
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  );
} else {
  storeEnhancers = compose(applyMiddleware(thunk));
}

const store = createStore(reducers, initialStates, storeEnhancers);

// Utils
export const getColors = () => {
  return store.getState().app.colors;
};

export default store;