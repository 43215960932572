import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class QuestionContinue extends Metric {
  constructor() {
    super(document.getElementsByClassName('step__submit-button'), 'click');
    this.secondaryTarget = document.getElementsByClassName('step-option');
    this.secondaryTypes = ['click'];
    this.reset();
  }

  reset() {
    this.lastAnswerSelectedTime = null;
    this.continueSelectedTime = null;
  }

  start() {
    super.start();
    this.configureEventListeners(this.secondaryTarget, this.secondaryTypes, this.useCapture, true);
  }

  stop() {
    super.stop();
    this.configureEventListeners(this.secondaryTarget, this.secondaryTypes, this.useCapture, false);
  }

  handleEvent(event) {
    if ('nodeName' in event.target) {
      if (event.target.nodeName === 'INPUT') {
        this.lastAnswerSelectedTime = Date.now();
        this.debug('QuestionContinue.lastAnswer', this.lastAnswerSelectedTime);
      } else if (event.target.nodeName === 'BUTTON') {
        this.continueSelectedTime = Date.now();
        this.debug('QuestionContinue.continue', this.continueSelectedTime);
      }
    }
  }

  getEvents() {
    if (!this.lastAnswerSelectedTime || !this.continueSelectedTime) {
      return [];
    }

    const time = this.continueSelectedTime - this.lastAnswerSelectedTime;
    return [
      new TrackingEvent('continue', null, this.continueSelectedTime, time),
    ];
  }
}