/* polyfills.js */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'url-search-params-polyfill';
import cssVars from "css-vars-ponyfill";
import {rootElementId} from '../settings/main';

// Apply fonts to root element for IE
if (!!window.MSInputMethodContext && !!document.documentMode) { // Internet Exploiter only
    cssVars({
        rootElement: document.querySelector('#' + rootElementId),
        onlyLegacy: true,
        variables: {
            "font-fallback": "Helvetica",
            "font-regular": "Proxima Nova",
            "font-bold": "Proxima Nova Bold",
            "font-semibold": "Proxima Nova Semibold",
            "font-extrabold": "Proxima Nova Extrabold",
        }
    });
}
