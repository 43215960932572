import React from 'react';
import PropTypes from 'prop-types';

export default class TextAnswer extends React.Component {

  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  static propTypes = {
    id: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
  };

  componentDidMount() {
    if (this.textInput.current) {
      this.textInput.current.focus(); // Autofocus on render
    }
  }

  onChangeValue(event) {
    this.props.valueChanged(event.target.value);
  }

  render() {
    let pattern = null;
    let placeholder = null;
    if (this.props.type === 'postalcode') {
      pattern = '[0-9]{3}-[0-9]{4}';
      placeholder = '000-0000'
    }
    else if (this.props.type === 'zipcode') {
      pattern = '[0-9]{5}';
      placeholder = '00000'
    }
    return (
        <div className={`form-field step-option step-option--text`} key={this.props.id}>
          <input
              ref={this.textInput}
              type="text"
              required={true}
              id={`${this.props.id}`} name={this.props.id}
              onChange={this.onChangeValue.bind(this)}
              pattern={pattern}
              placeholder={placeholder}
              title={placeholder}
          />
        </div>
    );
  }
}