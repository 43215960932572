import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Spinner from './spinner';

export default function Processing(props) {

  const [isWaitingLong, setWaitingLong] = useState(false);
  const longWaitTimeout = 3000; // 3 seconds
  let timer = null;

  useEffect(() => {
    if (props.longWaitText) {
      timer = setTimeout(() => {setWaitingLong(true)}, longWaitTimeout);
    }

    // Clean up when un-mounting
    return function cleanup() {
      clearTimeout(timer);
    };
  });

  return(
    <div className="processing">
      <div className="processing__content">
        <Spinner className="processing__spinner"/>
        <h2 className="processing__text">{props.text}</h2>
        { isWaitingLong && <h2 className="processing__text">{props.longWaitText}</h2> }
      </div>
    </div>
  );
}

Processing.propTypes = {
  text: PropTypes.string,
  longWaitText: PropTypes.string,
};