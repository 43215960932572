import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class QuestionHover extends Metric
{
  constructor() {
    super(document.getElementsByClassName('step__header'), ['mouseenter', 'mouseleave']);
    this.questionIsHovered = false;
    this.hoverStartTime = null;
    this.reset();
  }

  reset() {
    this.totalTime = 0;
  }

  handleEvent(event) {
    if (event.target.nodeName !== 'SECTION') {
      return;
    }
    if (event.type === 'mouseenter' && !this.questionIsHovered) {
      this.questionIsHovered = true;
      this.hoverStartTime = event.timeStamp;
      this.debug('QuestionHover.start', null);
    } else if (event.type === 'mouseleave' && this.questionIsHovered) {
      this.questionIsHovered = false;
      const hoverTime = event.timeStamp - this.hoverStartTime;
      this.totalTime += hoverTime;
      this.debug('QuestionHover.stop', hoverTime);
    }
  }

  getEvents() {
    if (this.totalTime === 0) {
      return [];
    }

    return [
        new TrackingEvent('question', null, Date.now(), this.totalTime)
    ];
  }
}
