import React from 'react';

import { RulerSVG } from '../../settings/svg';
import RangeSlider from './range-slider';
import PropTypes from "prop-types";

export default class RangeAnswer extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      options: props.options || [],
      defaultValue: '...',
      size: 256,
      positions: { // Positions of individual sliders (between 0 and 1)
        min: 0.1,
        max: 0.9,
      },
      values: { // Actual value that the user selected
        min: 0,
        max: 0,
      },
      decimals: (props.stepSize % 1 !== 0) ? 2 : 0, // 0 decimals for whole numbers, 2 for floats
      minSliderMoved: false,
      maxSliderMoved: false,
    }
  }

  positionToValue(position) {
    const range = (this.props.maxValue - this.props.minValue);
    const value = range * position + this.props.minValue;
    return parseFloat(value.toFixed(this.state.decimals));
  }

  static propTypes = {
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    options: PropTypes.array,
    stepSize: PropTypes.number.isRequired,
    rangeUnit: PropTypes.string.isRequired,
    instruction: PropTypes.string.isRequired,
    valueChanged: PropTypes.func,
  };

  onMinChangePosition(min) {
    // Limit min position to always be less than max position
    const minPosition = min < this.state.positions.max ? min : this.state.positions.max;

    this.setState({minSliderMoved: true});

    this.updateValues(minPosition, this.state.positions.max);
  }

  onMaxChangePosition(max) {
    // Limit max position to always be greater than min position
    const maxPosition = max > this.state.positions.min ? max : this.state.positions.min;

    this.setState({maxSliderMoved: true});

    this.updateValues(this.state.positions.min, maxPosition);
  }

  updateValues(min, max) {
    this.setState({
      positions: {
        min: min,
        max: max,
      },
      values: {
        min: this.positionToValue(min),
        max: this.positionToValue(max),
      }
    });

    if (this.state.minSliderMoved && this.state.maxSliderMoved) {
      this.props.valueChanged(this.state.values);
    }
  }

  render() {

    return(
      <div className="range">

        <RangeSlider
          className="range__slider range__slider--min"
          defaultValue={this.state.defaultValue}
          startPosition={this.state.positions.min}
          min={0}
          max={this.state.positions.max}
          minValue={this.props.minValue}
          stepSize={this.props.stepSize}
          range={this.props.maxValue - this.props.minValue}
          rangeUnit={this.props.rangeUnit}
          rangeDecimals={this.state.decimals}
          onChange={this.onMinChangePosition.bind(this)}
        />

        <div className="range__ruler range__thumb--min">
          <span className="range__ruler-unit-max">{this.props.rangeUnit} {this.props.maxValue}</span>
          <div
            className="range__ruler-value"
            style={{
              height: `${this.state.size * (this.state.positions.max - this.state.positions.min)}px`,
              transform: `translateY(${this.state.size - this.state.size * this.state.positions.max}px)`,
            }}
          />
          <span className="range__ruler-unit-min">{this.props.rangeUnit} {this.props.minValue}</span>
          <RulerSVG/>
        </div>

        <RangeSlider
          className="range__slider range__slider--max"
          defaultValue={this.state.defaultValue}
          startPosition={this.state.positions.max}
          min={this.state.positions.min}
          max={1}
          minValue={this.props.minValue}
          stepSize={this.props.stepSize}
          range={this.props.maxValue - this.props.minValue}
          rangeUnit={this.props.rangeUnit}
          rangeDecimals={this.state.decimals}
          onChange={this.onMaxChangePosition.bind(this)}
        />

        <p className="range__instruction">{this.props.instruction}</p>

      </div>

    );
  }
}