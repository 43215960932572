import React from 'react';
import PropTypes from 'prop-types';

import ChoiceAnswer from "./answer-type/choice";
import OptionsAnswer from './answer-type/options';
import NumericAnswer from './answer-type/numeric';
import TextAnswer from './answer-type/text';
import RangeAnswer from './answer-type/range';
import ReactResizeDetector from 'react-resize-detector';
import Indicator from '../components/indicator';
import {Translation} from "react-i18next";
import colors from '../../stylesheets/settings/_colors.scss';
import {notifyParentOfNewHeight} from '../utils/iframe';
import reportValidity from 'report-validity';

export default class Question extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      answer: null,
      index: null,
      time: 0,
      timerStarted: false,
    };

    this.form = HTMLFormElement;
  }

  static propTypes = {
    image: PropTypes.string,
    sequenceNumber: PropTypes.number.isRequired,
    submitAnswer: PropTypes.func.isRequired,
    text: PropTypes.string,
    time: PropTypes.number,
    title: PropTypes.string,
    lowerLimit: PropTypes.number,
    upperLimit: PropTypes.number,
    stepSize: PropTypes.number,
    type: PropTypes.string.isRequired,
    validations: PropTypes.arrayOf(PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }))
  };

  show() {

    if (!this.state.timerStarted) {
      this.setState({timerStarted: true});
      // start timer
      if (this.props.time) {
        this.interval = setInterval(this.onTimerInterval.bind(this), 1000);
      }
    }
  }

  onTimerInterval() {
    // check time
    if (this.state.time < this.props.time) {
      this.setState({time: this.state.time + 1});
    }
    else {
      this.stopTimer();
      // give back a none answer
      this.props.submitAnswer(null);
    }
  }

  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  /**
   * When a user clicks the submit button
   * @param {Object} event
   */
  onSubmitAnswer(event) {
    event.preventDefault();
    // Check and report html5 form validity to user, returns true if valid
    if (reportValidity(this.form)) {
      this.stopTimer();
      this.props.submitAnswer(this.state.answer, this.state.index);
    }
  }

  /**
   * When a user selects an answer
   * @param {String} value
   * @param {Integer} index
   */
  onValueChanged(value, index) {
    this.setState({answer: value, index: index});
    notifyParentOfNewHeight();
  }

  renderTimer() {

    if (!this.props.time) {
      return;
    }

    return (
      <Indicator
        extraClasses="step__indicator"
        timer={true}
        percentage={ this.state.time / this.props.time * 100}
      />
    );
  }

  componentDidMount() {
    notifyParentOfNewHeight();
  }

  render() {
    return(
      <Translation>
        {(t) =>
          <div className="step">
            <section className="step__header">
              <h1 className="step__title">{this.props.title || t('interface.question') + ' ' + this.props.sequenceNumber}</h1>
              <p className="step__description">{this.props.text}</p>
              {this.renderTimer()}
            </section>
            <section className="step__content">
              <ReactResizeDetector handleWidth>
                {(width) => <div>
                    <div className="step__border" style={{borderLeft: width/2 + 'px solid ' + colors.colorLight}}/>
                    <div className="step__border" style={{borderRight: width/2 + 'px solid ' + colors.colorLight}}/>
                </div>}
              </ReactResizeDetector>

              {this.props.image ?
                  <figure className="step__image">
                    <img alt="" src={this.props.image} onLoad={notifyParentOfNewHeight}/>
                  </figure>
                  : null
              }
              <form className="step__form" onSubmit={this.onSubmitAnswer.bind(this)} ref={comp => {this.form = comp}}>
                {
                  (() => {
                    switch (this.props.type) {
                      case 'level': // likert scale
                      case 'radio': // multiple choice
                      case 'image': // image multiple choice
                        return <OptionsAnswer {...this.props} valueChanged={this.onValueChanged.bind(this)} onLoad={notifyParentOfNewHeight}/>;
                      case 'age': // numeric age question with custom error message
                      case 'numeric': // single numeric input
                        return <NumericAnswer {...this.props} valueChanged={this.onValueChanged.bind(this)}/>;
                      case 'choice': // choice of 2 options
                         return <ChoiceAnswer {...this.props} valueChanged={this.onValueChanged.bind(this)} />;
                      case 'decimal_range': // Min-max price range
                      case 'integer_range': // Min-max number range
                        // eslint-disable-next-line no-case-declarations
                        const props = {
                          ...this.props,
                          minValue: this.props.validations.min,
                          maxValue: this.props.validations.max,
                          stepSize: this.props.stepSize,
                          rangeUnit: this.props.type === 'integer_range' ? '' : t('input.currency'),
                          instruction: t('input.range_slider.hint'),
                        };
                        return <RangeAnswer {...props} valueChanged={this.onValueChanged.bind(this)}/>;
                      case 'postalcode':
                      case 'zipcode':
                        return <TextAnswer {...this.props} valueChanged={this.onValueChanged.bind(this)}/>;
                      default:
                        return <div>Unknown question type: {this.props.type}
                          <button className="step__submit-button" onClick={this.props.submitAnswer}>
                            {t('interface.next_question')}
                          </button>
                        </div>;
                    }
                  })()
                }
              </form>
            </section>
            <section className="step__footer">
              <button className="step__submit-button" onClick={this.onSubmitAnswer.bind(this)}
                      hidden={this.state.answer === null}>{t('interface.next_question')}</button>
            </section>
          </div>
        }
      </Translation>
    );
  }
}