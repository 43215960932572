export default class Metric {
  /**
   * @param {EventTarget} target
   * @param {string|string[]} type
   * @param {boolean} useCapture
   */
  constructor(target, type, useCapture = true) {
    this.target = target;
    this.types = Array.isArray(type) ? type : [type];
    this.useCapture = useCapture;
    this.events = [];
    this.debugging = false;
  }

  /**
   * Starts measurements for this metric
   */
  start() {
    if (!this.target) {
      return;
    }
    this.configureEventListeners(this.target, this.types, this.useCapture, true);
  }

  /**
   * Stops measurements for this metric
   */
  stop() {
    if (!this.target) {
      return;
    }
    this.configureEventListeners(this.target, this.types, this.useCapture, false);
  }

  /**
   * @param {EventTarget} target
   * @param {string[]} types
   * @param {boolean} useCapture
   * @param {boolean} add Set to false to remove instead
   */
  configureEventListeners(target, types, useCapture, add) {
    if (target instanceof HTMLCollection) {
      for (let i in target) {
        if (target.hasOwnProperty(i)) {
          for (let t in types) {
            if (types.hasOwnProperty(t)) {
              // Wrap in try-catch, some older browsers are not compatible.
              try {
                if (add) {
                  target[i].addEventListener(types[t], this, useCapture);
                } else {
                  target[i].removeEventListener(types[t], this);
                }
              } catch (error) {
                this.debug('Error in configureEventListeners', error);
                return;
              }
            }
          }
        }
      }
      return;
    }

    for (let t in types) {
      if (types.hasOwnProperty(t)) {
        if (add) {
          target.addEventListener(types[t], this, useCapture);
        } else {
          target.removeEventListener(types[t], this);
        }
      }
    }
  }

  /**
   * Returns the current metric data
   * @return {TrackingEvent[]}
   */
  getEvents() {
    return this.events;
  }

  /**
   * Resets the counters for the metric
   */
  reset() {
    this.events = [];
  }

  /**
   * Handles the counter for the metric
   * @param {Event} event
   */
  // eslint-disable-next-line no-unused-vars
  handleEvent(event) {
  }

  /**
   * Use for debugging events. Will only output if debugging = true.
   * @param {String} eventName
   * @param {Object} context
   */
  debug(eventName, context) {
    if (this.debugging) {
      // eslint-disable-next-line no-console
      console.log(eventName, context);
    }
  }
}