import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class Click extends Metric
{
  constructor() {
    super(document, 'click');
    this.lastClick = null;
    this.clicks = 0;
  }

  reset() {
    super.reset();
    this.clicks = 0;
  }

  handleEvent(event) {
    // Click events on labels trigger an additional click on the input. We want to ignore this.
    if (this.lastClick && this.lastClick.nodeName === 'LABEL' && this.lastClick.htmlFor === event.target.id) {
      return;
    }
    this.debug('Click', event.target);
    this.clicks++;
    this.lastClick = event.target;
  }

  getEvents() {
    if (this.clicks === 0) {
      return [];
    }
    return [new TrackingEvent('click', null, Date.now(), this.clicks)];
  }
}
