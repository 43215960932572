import React, {Component} from 'react';
import {connect} from 'react-redux';
import Watermark from '../components/watermark';
import PropTypes from 'prop-types';
import {Translation} from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import {notifyParentOfNewHeight} from '../utils/iframe';

class CompletePage extends Component {

  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  };

  redirect() {
    window.top.location = this.props.backUrl;
  }

  componentDidMount() {
    notifyParentOfNewHeight();
  }

  render() {
    return (
        <Translation>
          {(t, {i18n}) =>
              <div className="page template-primary">
                <div className="page__background">
                  <Watermark/>
                </div>
                <div className="page__container">
                  <section className="page__header"/>
                  <section className="page__content">
                    <h1 className="page__title">{this.props.content.title}</h1>
                    <div className="page__text"><ReactMarkdown source={this.props.content.text} /></div>
                  </section>
                  <section className="page__footer"/>
                </div>
              </div>
          }
        </Translation>
    );
  }
}

const mapStateToProps = (state, props) => {

  return {
    content: state.app.content.complete ? state.app.content.complete[0] : {title: '', text: ''},
  };
};

export default connect(mapStateToProps)(CompletePage);