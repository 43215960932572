import React, {Component} from "react";
import PropTypes from "prop-types";


export default class Lightbox extends Component {

    static propTypes = {
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    };

    render() {

        return (
            <div className="lightbox">
                <div className="lightbox__content">
                    <button className="lightbox__close-button" onClick={this.props.onClose}>
                        <i className="icon-close"/></button>
                    <h2 className="lightbox__title">{this.props.title}</h2>
                    <p className="lightbox__text">{this.props.text}</p>
                </div>
            </div>
        );
    }
}