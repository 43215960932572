import {getNextQuestion} from '../utils/api';
import {previewQuestion} from '../utils/preview';

/*
 * Action types
 */
export const QUESTION_LOADED = 'questionnaire:questionLoaded';
export const FETCH_FAILED = 'questionnaire:fetchFailed';
export const CLEAR_ERROR = 'questionnaire:clearError';
export const PREVIEW_QUESTION = 'questionnaire:previewQuestion';

export function loadNextQuestion(pingUrl, answer, index, questionId, step, callback) {
  return dispatch => {
    getNextQuestion(pingUrl, answer, index, questionId, step,
        response => {
          // If error returned
          if (!response.success) {
            dispatch({
              type: FETCH_FAILED,
              payload: response.message,
            });
            return callback(response);
          }

          // If no next question returned
          if (response.finished === true || response.redirect) {
            return callback(response);
          }

          // If next question returned
          dispatch({
            type: QUESTION_LOADED,
            payload: response.question,
          });
          callback({
            success: true,
            question: response.question,
          });
        },
    );
  };
}

export function clearError() {
  return dispatch => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
}

export function loadPreviewQuestion() {
  return (dispatch) => {
    dispatch({
      payload: Object.assign(previewQuestion),
      type: PREVIEW_QUESTION,
    });
  };
}