import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import PropTypes from "prop-types";


export default class Indicator extends React.Component {

    static propTypes = {
        extraClasses: PropTypes.string,
        percentage: PropTypes.number,
        unit: PropTypes.string,
        value: PropTypes.any,
    };

    render() {

        return (
            <div className={`indicator ${this.props.timer ? 'indicator--timer' : ''} ${this.props.extraClasses}`}>
                <span className="indicator__value">{this.props.value}</span>
                <span className="indicator__unit">{this.props.unit}</span>
                {this.props.timer ?
                    <CircularProgressbar
                        className="indicator__timer"
                        percentage={this.props.percentage}
                        strokeWidth={50}
                        textForPercentage={null}
                        styles={{
                            path: {strokeLinecap: 'butt'},
                            text: {fill: '#000'},
                        }}
                    />
                    : null
                }
            </div>
        );
    }
}