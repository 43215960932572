import {saveBehaviourMetrics} from './api';
import Click from './tracking/click';
import AnswerSelect from './tracking/answerSelect';
import MouseScroll from './tracking/mouseScroll';
import MouseMovement from './tracking/mouseMovement';
import AnswerHover from './tracking/answerHover';
import AppFocus from './tracking/appFocus';
import QuestionContinue from './tracking/questionContinue';
import QuestionHover from './tracking/questionHover';

export default class BehaviourTracking
{
  constructor() {
    this.listeners = [
      new AnswerHover(),
      new AnswerSelect(),
      new AppFocus(),
      new Click(),
      new MouseMovement(),
      new MouseScroll(),
      new QuestionContinue(),
      new QuestionHover(),
    ];
    this.listenersAdded = false;
  }

  start() {
    if (!this.listenersAdded) {
      this.listeners.forEach((listener) => {
        listener.start();
      });
      this.listenersAdded = true;
    }
  }

  stop() {
    this.listeners.forEach((listener) => {
      listener.stop();
    });
    this.listenersAdded = false;
  }

  restart() {
    this.stop();
    this.start();
  }

  report(subject) {
    let events = [];
    this.listeners.forEach((listener) => {
      events = events.concat(listener.getEvents());
      listener.reset();
    });

    if (this.listeners.length > 0) {
      this.listeners[0].debug('BehaviourTracking.report', events);
    }

    saveBehaviourMetrics({
      subject: subject,
      events: events,
    });
  }
}