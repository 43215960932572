import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class AnswerSelect extends Metric
{
  constructor() {
    super(document.getElementsByClassName('step-option'), 'click');
  }

  handleEvent(event) {
    if (event.target.nodeName === 'INPUT') {
      this.debug('AnswerSelect', event.target);
      const regex = /[A-Z]+\d+-[A-Z]/g;
      const nameMatch = event.target.id.match(regex);
      if (nameMatch && nameMatch.length === 1) {
        this.events.push(new TrackingEvent('answerSelect', nameMatch[0], Date.now()));
      }
    }
  }
}
