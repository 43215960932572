import React, { Component } from "react";
import { HexagonSVG } from '../settings/svg';


export default class Watermark extends Component {

  render() {
    return(
      <div className="watermark">
        <HexagonSVG className="hexagon-left" id={"watermark-hex-left"} />
        <HexagonSVG className="hexagon-center" id={"watermark-hex-center"} />
        <HexagonSVG className="hexagon-right" id={"watermark-hex-right"} />
      </div>
    );
  }
}