import React, {Component} from "react";
import PropTypes from "prop-types";
import {Translation} from 'react-i18next';

export default class Prompt extends Component {

  static propTypes = {
    buttonHandler: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {

    return (
        <Translation>
          {(t, {i18n}) =>
              <div className="prompt">
                <div className="prompt__content">
                  <p className="prompt__title">{t(this.props.title)}</p>
                  <p className="prompt__message">{t(this.props.message)}</p>
                  <button className="prompt__submit"
                          onClick={this.props.buttonHandler}>{t(this.props.buttonLabel)}</button>
                </div>
              </div>
          }
        </Translation>);
  }
}