import React from 'react';

export function HexagonSVG(props) {

  const gradientID = `watermark-gradient-${props.id}`;

  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient id={gradientID} gradientTransform="rotate(0)">
          <stop offset="0%" stopColor="#000" stopOpacity="0.2" />
          <stop offset="100%" stopColor="#000" stopOpacity="0" />
        </linearGradient>
      </defs>
      <polygon points="16 0 29.8564065 8 29.8564065 24 16 32 2.14359354 24 2.14359354 8" fill={`url(#${gradientID}`}/>
    </svg>
  );
}

export function RulerSVG(props) {

  return (
    <svg viewBox="0 0 36 256" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0" y="1" width="36" height="2" rx="1"/>
      <rect x="0" y="73" width="36" height="2" rx="1"/>
      <rect x="0" y="37" width="36" height="2" rx="1"/>
      <rect x="0" y="109" width="36" height="2" rx="1"/>
      <rect x="0" y="181" width="36" height="2" rx="1"/>
      <rect x="0" y="145" width="36" height="2" rx="1"/>
      <rect x="0" y="217" width="36" height="2" rx="1"/>
      <rect x="0" y="253" width="36" height="2" rx="1"/>
      <rect x="8" y="10" width="20" height="2" rx="1"/>
      <rect x="8" y="82" width="20" height="2" rx="1"/>
      <rect x="8" y="46" width="20" height="2" rx="1"/>
      <rect x="8" y="118" width="20" height="2" rx="1"/>
      <rect x="8" y="190" width="20" height="2" rx="1"/>
      <rect x="8" y="154" width="20" height="2" rx="1"/>
      <rect x="8" y="226" width="20" height="2" rx="1"/>
      <rect x="8" y="19" width="20" height="2" rx="1"/>
      <rect x="8" y="91" width="20" height="2" rx="1"/>
      <rect x="8" y="55" width="20" height="2" rx="1"/>
      <rect x="8" y="127" width="20" height="2" rx="1"/>
      <rect x="8" y="199" width="20" height="2" rx="1"/>
      <rect x="8" y="163" width="20" height="2" rx="1"/>
      <rect x="8" y="235" width="20" height="2" rx="1"/>
      <rect x="8" y="28" width="20" height="2" rx="1"/>
      <rect x="8" y="100" width="20" height="2" rx="1"/>
      <rect x="8" y="64" width="20" height="2" rx="1"/>
      <rect x="8" y="136" width="20" height="2" rx="1"/>
      <rect x="8" y="208" width="20" height="2" rx="1"/>
      <rect x="8" y="172" width="20" height="2" rx="1"/>
      <rect x="8" y="244" width="20" height="2" rx="1"/>
    </svg>
  );
}