export default class TrackingEvent
{
  /**
   * @param {String} name
   * @param {String} subject
   * @param {number} time
   * @param {number|null} value
   */
  constructor(name, subject, time, value = null) {
    this.name = name;
    this.subject = subject;
    this.time = time;
    this.value = value;
  }
}
