import {rootElementId} from "../settings/main";
import cssVars from "css-vars-ponyfill";
import store from './store';

// Theme colors, when no theme has been loaded yet (black and white)
export const initialColors = {
  // Intro/complete pages
  pageBg: '#fff', // Page background
  pageText: '#000', // Slider text
  pageBtnBg: '#C0C0C0', // Start button background
  pageBtnText: '#000', // Start button text
  // Processing/load screen
  loadBg: '', // Screen background
  loadText: '#333', // Text
  // Question page
  questionHeadBg: '#ccc', // Question header background
  questionHeadText: '', // Question header text
  questionBg: '#f9f9f9', // Page background
  questionAnswerBg: '#fff', // Answer background
  questionAnswerActive: '', // Answer background when selected
  questionAnswerText: '#000', // Answer text
  questionAnswerActiveText: '#f', // Answer text when selected
  questionAnswerHelper: '', // Answer helper, like icons
  questionBtnBg: '#C0C0C0', // Continue button background
  questionBtnText: '', // Continue button text
  // Error prompt modal
  promptOverlay: 'rgba(0, 0, 0, 0.27)', // Overlay color (transparent)
  promptBg: '#fff', // Modal background
  promptText: '#000', // Modal text
  promptBtnBg: '#C0C0C0', // OK button background
  promptBtnText: '#000', // OK button text
  // Progressbar
  progress: '#00c',
};

/**
 * Applies the colors currently in the redux store to the page
 */
export function applyColorsFromStore() {
  const colors = store.getState().app.colors;
  for (let key in colors) {
    if (!colors.hasOwnProperty(key)) {
      continue;
    }
    document.getElementById(rootElementId).style.setProperty('--' + key, colors[key]);
  }

  if (!!window.MSInputMethodContext && !!document.documentMode) { // Internet Exploiter only
    cssVars({
      onlyLegacy: true,
      variables: colors
    });
  }
}
