import {LOADED_CONFIGURATION, PREVIEW_CONFIGURATION} from '../actions/app';
import {initialColors} from '../utils/theme';

export const initialAppState = {
  colors: initialColors,
  content: {intro: [], complete: []},
  questionnaireMeta: {},
};

export default function appReducer(state = '', action) {

  switch(action.type) {
    case PREVIEW_CONFIGURATION:
      return action.payload;
    case LOADED_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
}