import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';

import Lightbox from '../components/lightbox';
import Watermark from '../components/watermark';
import { useTranslation } from 'react-i18next';

export default function ServicesPage(props) {

  // States
  const [lightbox, setLightbox] = useState(null);
  const [servicesAuthorized, setServicesAuthorized] = useState(false);

  // i18n
  const { t } = useTranslation();

  function openLightbox(title, text) {
    setLightbox({title: title, text: text});
  }

  return (
    <div className="page template-primary">
      <div className="page__background">
        <Watermark/>
      </div>
      <div className="page__container">
        <section className="page__header">
          <h1 className="page__title">{t('services.share_title')}</h1>
          <p className="page__text">{t('services.share_intro')}</p>
        </section>
        <section className="page__content">
          <div className="services">
            <div className="services__privacy">
              <button className="services__privacy-button" onClick={() => {
                openLightbox(t('services.privacy_title'), t('services.privacy_text'))
              }}>
                {t('services.privacy_title')} <i className="icon-chevron-down"/>
              </button>
            </div>
          </div>
        </section>
        <section className="page__footer">
          {
            servicesAuthorized ?
                <NavLink className="page__next-button" to="/questionnaire">{t('interface.start_btn')}</NavLink> :
                <NavLink className="page__link" to="/questionnaire">{t('services.no_sharing')}</NavLink>
          }
        </section>
      </div>
      {
        !lightbox ||
        <Lightbox
            title={lightbox.title}
            text={lightbox.text}
            onClose={() => {setLightbox(null)}}
        />
      }
    </div>
  );
}