import React, {useState} from 'react';
import Prompt from './prompt';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import {connect} from 'react-redux';
import {clearError} from '../actions/questionnaire';

function ErrorPrompt(props) {
  let title = props.title || 'errors.general.title',
      message = props.message || 'errors.general.text',
      buttonLabel = props.buttonLabel || 'interface.prompt_dismiss';

  const [eventId, setEventId] = useState(false);

  const reportToSentry = (error, errorInfo) => {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      setEventId(Sentry.captureException(error));
    });
  };
  if (!eventId && props.message) {
    reportToSentry(props.message, props.errorInfo || {});
  }

  const buttonHandler = () => {
    props.clearError();
    if (props.buttonHandler) {
      props.buttonHandler(props.message);
    }
  };

  if (props.message === 'errors.questionnaire_completed') {
    title = 'errors.general.title';
    buttonLabel = 'interface.continue';
  }

  return (
      <Prompt {...{title, message, buttonLabel, buttonHandler}}/>
  );
}

ErrorPrompt.propTypes = {
  buttonHandler: PropTypes.func,
  message: PropTypes.string,
  errorInfo: PropTypes.object,
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  clearError: PropTypes.func.isRequired
};

export default connect(null, {clearError})(ErrorPrompt);