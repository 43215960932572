import React from 'react';
import PropTypes from "prop-types";

export default class OptionsAnswer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      answers: props.answers || [],
    }
  }

  static propTypes = {
    answers: PropTypes.array.isRequired,
    id: PropTypes.any.isRequired,
    onLoad: PropTypes.func,
    type: PropTypes.string.isRequired,
    valueChanged: PropTypes.func,
  };

  render() {
    return (
      <div>
        {
          this.state.answers.map( (answer, index) => {

            const emoticonClass = "likert" + this.state.answers.length;
            const id = `answer-${answer.id}`;

            return (
              <div className={`form-field step-option step-option--${this.props.type}`} key={answer.id}>
                <input
                  type="radio"
                  value={answer.value}
                  id={id} name={this.props.id}
                  onChange={() => this.props.valueChanged(answer.value, index)} />
                <label htmlFor={id}>
                {this.props.type === 'image'
                    ? <figure className="step-option--image__figure">
                      <img alt="" src={answer.image} onLoad={this.props.onLoad ? this.props.onLoad: null} />
                    </figure>
                    : null}
                {this.props.type === 'level' ? <i className={`step-option__icon ${emoticonClass} icon-emotion-${answer.value}`}/> : null}
                {this.props.type !== 'image' ? answer.text : null}
                <i className="icon-check step-option__check"/>
                </label>
              </div>
            );
          })
        }
      </div>
    );
  }
}