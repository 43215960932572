import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import bn from "../../translations/bn.json";
import cz from "../../translations/cz.json";
import en from "../../translations/en.json";
import es from "../../translations/es.json";
import it from "../../translations/it.json";
import fr from "../../translations/fr.json";
import jp from "../../translations/jp.json";
import ka from "../../translations/ka.json";
import nl from "../../translations/nl.json";
import pl from "../../translations/pl.json";
import ro from "../../translations/ro.json";
import ru from "../../translations/ru.json";
import si from "../../translations/si.json";
import sk from "../../translations/sk.json";
import tl from "../../translations/tl.json";
import tr from "../../translations/tr.json";
import uk from "../../translations/uk.json";
import vi from "../../translations/vi.json";

i18n
  .use(initReactI18next)
  .init({
    resources: { bn, cz, en, es, fr, it, jp, ka,  nl, pl, ro, ru, si, sk, tl, tr, uk, vi },
    fallbackLng: "en",
    debug: false,
  });

i18n.languages = ["en"]; // For some reason, it's nagging if we don't add this

export default i18n;
