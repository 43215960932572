import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

export default function NumericAnswer(props) {

  const {t} = useTranslation();
  const numericInput = useRef(null);

  useEffect(() => {
    if (!numericInput.current) {
      return;
    }
    numericInput.current.focus(); // Autofocus on render
    numericInput.current.addEventListener('input', onInputChange);

    return function cleanup() {
      numericInput.current.removeEventListener('input', onInputChange);
    }
  });

  function onInputChange(event) {
    props.valueChanged(event.target.value);

    // Custom validity message only applies to age type
    if (props.type !== 'age') {
      return;
    }
    const validity = event.target.validity;
    if (validity.rangeUnderflow || validity.rangeOverflow) {
      event.target.setCustomValidity(t('errors.age_out_of_range'));
    } else {
      event.target.setCustomValidity("");
    }
  }

  return (
    <div className={`form-field step-option step-option--numeric`} key={props.id}>
      <input
          ref={numericInput}
          type="number"
          step="1"
          required={true}
          id={props.id} name={props.id}
          min={props.validations && props.validations.min}
          max={props.validations && props.validations.max}
      />
    </div>
  );
}

NumericAnswer.propTypes = {
  id: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  validations: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
};
