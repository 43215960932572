import Metric from './metric';
import TrackingEvent from './trackingEvent';

export default class AppFocus extends Metric {

  constructor() {
    super(window, ['focus', 'blur']);
    this.isBlurred = false;
  }

  handleEvent(event) {
    const now = Date.now();
    if (event.target !== window) { // Skip sub-events of window
      return;
    }
    if (event.type === 'blur') {
      this.isBlurred = true;
      this.events.push(new TrackingEvent('appBlur', null, now));
      this.debug('AppFocus.blur', now);
    } else if (event.type === 'focus' && this.isBlurred) { // Don't trigger on initial focus (if any)
      this.isBlurred = false;
      this.events.push(new TrackingEvent('appFocus', null, now));
      this.debug('AppFocus.focus', now);
    }
  }
}