import React, {Component} from 'react';
import {Provider} from 'react-redux';
import Store from './utils/store';
import {errorReporting} from './utils/errorReporting';
import Loader from './components/loader';
import {applyColorsFromStore} from './utils/theme';

errorReporting();

export default class App extends Component {

  componentDidMount() {
    applyColorsFromStore();
  }

  render() {
    return (
        <Provider store={Store}>
            <Loader/>
        </Provider>
    );
  }
}