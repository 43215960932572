import Translation from './i18n';
import {getToken} from './token';

const API_SERVER = process.env.REACT_APP_API_URL || 'https://pcs.advicerobo.com';
const API_VERSION = 'v1';
const API_URL = `${API_SERVER}/api/${API_VERSION}`;

const getValidHeaders = () => {
  const token = getToken();
  if (!token) {
    return null;
  }

  return {
    'Accept': 'application/json',
    'X-AR-Token': token,
  };
};

export function getQuestionnaire(onSuccessCallback, onFailureCallback) {
  const headers = getValidHeaders();
  if (headers === null) {
    return onFailureCallback();
  }

  fetch(`${API_URL}/questionnaire`,
      {
        method: 'GET',
        headers: headers,
      },
  ).then(response => {
    if (!response.ok) {
      return onFailureCallback();
    }
    return response.json().then(json => {
      onSuccessCallback(json);
    });
  }).catch(() => {
    return onFailureCallback();
  });
}

export function getNextQuestion(pingUrl, answer, index, questionId, step, callback) {
  const headers = getValidHeaders();
  if (headers === null) {
    return callback({success: false});
  }

  fetch(pingUrl ? API_SERVER + pingUrl : `${API_URL}/ping`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      answer: answer,
      index: index,
      questionId: questionId,
      step: step,
    }),
  }).then(response => {
    if (!response.ok) {
      if (response.status === 412) { // precondition failed = redirect
        return response.json().then(json => {
          return callback({success: true, redirect: json.url});
        });
      }
      return response.json().then((json) => {
        callback({success: false, message: json.message});
      });
    }
    if (response.status === 204) { // no content
      return callback({success: true, finished: true});
    }
    response.json().then(json => {
      return callback({success:true, question: json});
    });
  }).catch(() => {
    return callback({success: false, message: Translation.t('errors.connection_failed')});
  });
}

export function saveBehaviourMetrics(metrics) {
  const headers = getValidHeaders();
  fetch(`${API_URL}/event`, {
    method: 'POST',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(metrics),
  });
}
