import React from "react";
import {
    useParams
} from "react-router-dom";

function HostedCustomerRedirect() {
    const { slug, applicantId } = useParams();
    window.location.replace(`https://pcs.advicerobo.com/q/${slug}?id=${applicantId}`);

    return null;
}


export default HostedCustomerRedirect;