import './stylesheets/styles.scss';
import './scripts/utils/polyfills.js'
import React from 'react';
import ReactDOM from 'react-dom';

import App from './scripts/app';
import './scripts/utils/i18n';
import {rootElementId} from "./scripts/settings/main";

ReactDOM.render(<App />, document.getElementById(rootElementId));
